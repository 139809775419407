import React from "react";
import Truncate from "react-truncate";

import { modConstants } from "Constants";
import { Stack, Box, Header, Flex, Button, Text } from "Atoms";
import { formatModificationPrice } from "PriceUtils";

type ModificationOption = {
    name: string;
    price: number;
    addonPrice: number;
};

type ModificationKeys = "flavours" | "sizes";

type Modification = {
    name: ModificationKeys;
    options: ModificationOption[];
};

type Props = {
    isFromBundle: boolean;
    modifications: Modification[];
    onSelectModification: (key: ModificationKeys, value: ModificationOption) => void;
    selectedModifications: { flavours: ModificationOption | null; sizes: ModificationOption | null };
};

export const PosModificationsModalList: React.FC<Props> = ({
    modifications,
    onSelectModification,
    isFromBundle,
    selectedModifications
}) => {
    return (
        <Stack stretch={4}>
            {modifications.map(modification => {
                const header = modification.name === modConstants.MOD_SIZES ? "Storlekar" : "Smaker";

                return (
                    !!modification.options.length && (
                        <Box key={modification.name}>
                            <Header size="lg">{header}</Header>
                            <Flex wrap="wrap">
                                {modification.options.map(option => {
                                    const isSelected = selectedModifications[modification.name]?.name === option.name;

                                    return (
                                        <Button
                                            key={option.name}
                                            themeColor={isSelected ? "green" : "gray"}
                                            onClick={() => onSelectModification(modification.name, option)}
                                            w="10rem"
                                            h="10rem"
                                            flexDirection="column"
                                            fontSize={"xl"}
                                            mr={4}
                                            mb={4}
                                        >
                                            {/** @ts-ignore */}
                                            <Truncate lines={2}>
                                                <Header size="md">{option.name}</Header>
                                            </Truncate>
                                            <Text mt={3}>
                                                {formatModificationPrice(option.addonPrice, option.price, isFromBundle)}
                                            </Text>
                                        </Button>
                                    );
                                })}
                            </Flex>
                        </Box>
                    )
                );
            })}
        </Stack>
    );
};

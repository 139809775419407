import React from "react";

import { Flex, List } from "Atoms";

type Props = {
    content: React.ReactNode;
    footer: React.ReactNode;
};

export const PosCart: React.FC<Props> = ({ content, footer }) => (
    <Flex direction="column">
        <List flex="1" overflow="auto" pt={4}>
            {content}
        </List>
        <div>{footer}</div>
    </Flex>
);

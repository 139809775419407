import React, { useState, useEffect } from "react";
import { useQuery } from "react-apollo";
import { FiCheck } from "@react-icons/all-files/fi/FiCheck";
import { keyBy, flatMap, Dictionary, uniq } from "lodash";
import Truncate from "react-truncate";

import { Modal, ModalHeader, ModalBody, TableHead, TableRow, TableHeader, TableBody, TableCell } from "Molecules";
import { GET_ALLERGENS, GET_BUNDLE_PRODUCTS_AND_REF_PRODUCTS } from "GraphQLQueries";
import { useQopla, IModalContext } from "Providers";
import { NewButton, Flex, Box, Text, NewGrid, Header, Spinner } from "Atoms";
import { Allergen, MenuProductCategory } from "Types";
import { modalNames, PosTypes } from "Constants";

interface GetAllergensData {
    allAllergens: Allergen[];
}

type Product = {
    id: string;
    name: string;
    allergens: Allergen[] | [];
    bundleProductCategories?: {
        refProductIdList: string[];
    }[];
    refProductCategoryId: string;
};

type Props = {
    closeModal: () => void;
    menuProductCategories?: MenuProductCategory[];
    posType: PosTypes;
};

const refProductsToIdList = (allRefProducts: any[]): string[] => {
    return allRefProducts.reduce<string[]>((acc, refProduct) => {
        if (!!refProduct.bundleProductCategories) {
            const allProductIdsForBundle = flatMap(
                refProduct.bundleProductCategories,
                ({ refProductIdList }) => refProductIdList
            );

            acc.push(...allProductIdsForBundle);
        }

        acc.push(refProduct.id);

        return acc;
    }, []);
};

const getRefProductsIdsList = (
    allCompaniesRefProducts: any,
    menuProductCategories?: MenuProductCategory[]
): string[] => {
    let productsIds: string[] = [];

    if (!!menuProductCategories) {
        productsIds = flatMap(menuProductCategories, ({ menuProducts, menuBundleProducts }) => {
            //@ts-ignore
            const allProducts = [...menuProducts, ...menuBundleProducts].map(({ refProduct, refBundleProduct }) =>
                refProduct ? refProduct : refBundleProduct
            );
            return refProductsToIdList(allProducts);
        });
    } else {
        productsIds = refProductsToIdList(allCompaniesRefProducts);
    }

    return uniq(productsIds);
};

const toProducts = (refProductHashMap: Dictionary<any>, idsList: string[]): Product[] =>
    idsList.reduce<Product[]>((products, nextId) => {
        const product = refProductHashMap[nextId];
        if (!!product) {
            products.push(product);
        }
        return products;
    }, []);

export const AllergyModal: React.FC<IModalContext<Props>> = ({
    modalContent: { menuProductCategories, posType },
    closeModal
}) => {
    const [selectedAllergens, setSelectedAllergens] = useState<string[]>([]);
    const [refProductHashMap, setRefProductHashMap] = useState<Dictionary<any>>({});

    const { selectedCompany } = useQopla();

    const { data: allergensData, loading: allergensLoading } = useQuery<GetAllergensData>(GET_ALLERGENS);
    const { data: companyRefProductsData, loading: companyRefProductsLoading } = useQuery(
        GET_BUNDLE_PRODUCTS_AND_REF_PRODUCTS,
        {
            variables: {
                companyId: selectedCompany.id
            },
            fetchPolicy: "network-only"
        }
    );

    useEffect(() => {
        if (companyRefProductsData) {
            const allCompaniesRefProducts = [
                ...companyRefProductsData.getCompanyRefBundleProducts,
                ...companyRefProductsData.getCompanyRefProducts
            ];

            setRefProductHashMap(keyBy(allCompaniesRefProducts, "id"));
        }
    }, [companyRefProductsData]);

    if (allergensLoading || companyRefProductsLoading || !Object.values(refProductHashMap).length) {
        return (
            <Flex justifyContent="center" alignItems="center">
                <Spinner />
            </Flex>
        );
    }

    const onAllergenClick = (allergen: string, isChecked: boolean) => {
        if (isChecked) {
            return setSelectedAllergens(currSelectedAllergens =>
                currSelectedAllergens.filter(selectedAllergen => selectedAllergen !== allergen)
            );
        }
        return setSelectedAllergens(currSelectedAllergens => currSelectedAllergens.concat(allergen));
    };

    const allergens = allergensData
        ? allergensData.allAllergens.concat().sort((a, b) => a.sortOrder - b.sortOrder)
        : [];
    const allCompaniesRefProducts = [
        ...companyRefProductsData.getCompanyRefBundleProducts,
        ...companyRefProductsData.getCompanyRefProducts
    ];
    const productIdList: string[] = getRefProductsIdsList(allCompaniesRefProducts, menuProductCategories);
    const products = toProducts(refProductHashMap, productIdList).filter(
        ({ bundleProductCategories, allergens }) => !bundleProductCategories || !!allergens.length
    );
    const filteredProductsByAllergens = products
        .filter(({ allergens }) => {
            if (!selectedAllergens.length) return true;

            return !allergens.some((allergen: Allergen) =>
                !!allergen ? selectedAllergens.includes(allergen.name) : true
            );
        })
        .sort((a, b) => a.name.localeCompare(b.name));

    const isNotPOS = [PosTypes.EXPRESS, PosTypes.ONLINE].includes(posType);

    return (
        <Modal open isScrolling maxWidth="110rem">
            <ModalHeader display="flex" justifyContent="space-between" alignItems="center">
                <Header as="h3" size="lg" m="0">
                    Allergiguide
                </Header>
                <NewButton themeColor="red" size="lg" onClick={() => closeModal(modalNames.ALLERGY_MODAL)}>
                    Stäng
                </NewButton>
            </ModalHeader>
            <ModalBody p={[4, 6]}>
                <Flex direction="column">
                    <Flex direction={["column", "row"]}>
                        <Flex direction="column">
                            {isNotPOS && (
                                <Header as="h4" size="md" mb={3}>
                                    Klicka in det ni är allergisk mot:
                                </Header>
                            )}
                            <Flex direction="column" bg="gray.200" p={4} rounded="md" mr={[0, 4]} height="auto">
                                <NewGrid templateColumns={["repeat(2, 1fr)", "repeat(3, 100px)"]} gap={2}>
                                    {allergens.map((allergen: Allergen) => {
                                        let styles = {
                                            bg: "white",
                                            color: "black"
                                        };
                                        const isChecked = !!selectedAllergens.find(
                                            selectedAllergen => selectedAllergen === allergen.name
                                        );
                                        if (isChecked) {
                                            styles = {
                                                bg: "blue.400",
                                                color: "white"
                                            };
                                        }
                                        return (
                                            <Flex
                                                as="button"
                                                onClick={() => onAllergenClick(allergen.name, isChecked)}
                                                p={4}
                                                align="center"
                                                justify="center"
                                                height="60px"
                                                rounded="md"
                                                border="none"
                                                key={allergen.id}
                                                {...styles}
                                            >
                                                <Text as="span">{allergen.name}</Text>
                                            </Flex>
                                        );
                                    })}
                                </NewGrid>
                                <Flex align="flex-end">
                                    <Text as="span" fontWeight="bold" mt={4}>
                                        Bortfiltrerade produkter: {products.length - filteredProductsByAllergens.length}{" "}
                                        st
                                    </Text>
                                </Flex>
                            </Flex>
                        </Flex>
                        <Flex direction="column" overflow="auto" mt={[4, 0]}>
                            {isNotPOS && (
                                <Header as="h4" size="md" mb={3}>
                                    Produkter ni kan äta:
                                </Header>
                            )}
                            <Box position="relative" height="53vh" flex="1 1 auto" overflow="auto">
                                <Box overflowX="scroll" overflowY="visible" height="100%">
                                    <Box as="table" borderCollapse="collapse">
                                        <TableHead>
                                            <TableRow>
                                                <TableHeader position="sticky" top="0" zIndex={1}>
                                                    Produkt
                                                </TableHeader>
                                                {allergens.map(allergen => (
                                                    <TableHeader key={allergen.id} position="sticky" top="0" zIndex={1}>
                                                        {allergen.name}
                                                    </TableHeader>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {filteredProductsByAllergens.map((product, i) => {
                                                const bg = i % 2 === 0 ? "white" : "gray.100";
                                                return (
                                                    <TableRow key={`${product.name}-${i}`}>
                                                        <TableCell
                                                            bg={bg}
                                                            position="sticky"
                                                            left="0"
                                                            width="100px"
                                                            boxShadow="2xl"
                                                            zIndex={2}
                                                        >
                                                            {/** @ts-ignore */}
                                                            <Truncate width={150}>
                                                                <Text fontWeight="bold" fontSize="sm">
                                                                    {product.name}
                                                                </Text>
                                                            </Truncate>
                                                        </TableCell>
                                                        {allergens.map(allergen => {
                                                            const foundAllergen =
                                                                product.allergens &&
                                                                product.allergens.find((pa: any) =>
                                                                    !!pa ? pa.name === allergen.name : null
                                                                );
                                                            return (
                                                                <TableCell key={allergen.id} bg={bg}>
                                                                    {foundAllergen && (
                                                                        <Flex align="center" justify="center">
                                                                            <Box as={FiCheck} size="18px" />
                                                                        </Flex>
                                                                    )}
                                                                </TableCell>
                                                            );
                                                        })}
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Box>
                                </Box>
                            </Box>
                        </Flex>
                    </Flex>
                </Flex>
            </ModalBody>
        </Modal>
    );
};

import React from "react";

import { Addon } from "Types";
import { BaseBoxProps, Flex, Text, Box } from "Atoms";

type Props = {
    addons: Addon[];
    hidePrice?: boolean;
} & BaseBoxProps;

export const AddonsCartItem: React.FC<Props> = ({ addons, hidePrice = false, ...rest }) => {
    if (!addons) return null;
    return (
        <Box {...rest}>
            {addons.map((addon, idx) => {
                const sign = addon.price >= 0 ? "+" : "";
                const operator = addon.price !== 0 ? "+" : "•";
                return (
                    <Flex key={idx} justify="space-between" height="auto" {...rest}>
                        <Text as="span">
                            {operator} {addon.quantity}x {addon.name}
                        </Text>
                        {!hidePrice && (
                            <Text as="span" className="notranslate">
                                {sign} {addon.price * addon.quantity} kr
                            </Text>
                        )}
                    </Flex>
                );
            })}
        </Box>
    );
};

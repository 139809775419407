import uuidv4 from "uuid/v4";
import { round } from "lodash";

import {
    calculateDiscountRate,
    calculateProductPrice,
    getDiscountedCartTotalAdjustedQuantities,
    getDiscountedCartTotalOriginalPrice,
    roundToTwo,
    getUpdatedUnitPrice
} from "../../pos/utils";
import { getTotalAddonsPrice } from "Utils";
import { calculateMenuProductOrBundlePrice, getVatAmount } from "PriceUtils";
import { isVariablePriceType } from "ProductUtils";
import { getPrice, calculateBundleAddonPrice, getProductBasePrice } from "TempUtils";
import {
    APPLIED_DISCOUNT_TYPE,
    CartProduct,
    CustomerMetaStatus,
    DISCOUNT_TYPE,
    OrderWindow,
    OnlineFixedDiscount,
    Discount,
    CartIdAndQuantity,
    CombinedDiscount,
    OrderProduct,
    Modifications,
    FixedDiscount,
    MenuProduct,
    Addon,
    BundleProductCategory,
    MenuBundleProduct,
    Modification
} from "Types";
import { ScaleInputMode } from "../../pos/components/ScaleModal/ScaleModal";

/** Check product has a price adjust discount 'justera pris' */
export const isCartProductAPriceAdjust = (cartProduct: CartProduct) => {
    return (
        cartProduct.orderProduct.combinedDiscounts?.length === 1 &&
        cartProduct.orderProduct.combinedDiscounts[0].discountType === DISCOUNT_TYPE.PRICE_ADJUST
    );
};

/**
 * [FUNCTION] get menu product or menu bundle product vat rate
 * @param {CartProduct} cartProduct
 * @returns {number} vat rate
 */
export const getRefVatRate = (cartProduct: CartProduct) => {
    if (!!cartProduct?.menuProduct) {
        return cartProduct.menuProduct.refProduct.vatRate;
    } else if (!!cartProduct?.menuBundleProduct) {
        return cartProduct.menuBundleProduct.refBundleProduct.vatRate;
    }
};

/**
 * [TEST_SUITE] this function is in test
 * [FUNCTION] update all cart products and the discounts
 * @param {OrderWindow} orderWindow
 * @returns {OrderWindow} updated order window
 */
export const updateAllCartProductsDiscounts = (orderWindow: OrderWindow | Partial<OrderWindow>) => {
    const cartTotalOriginalPrice = getDiscountedCartTotalOriginalPrice(orderWindow);

    return (
        (orderWindow?.cartProducts?.map(cartProduct => {
            return recalculatePriceAndDiscount(
                cartProduct,
                orderWindow.discount,
                orderWindow?.discountedProductIds ?? [],
                cartTotalOriginalPrice,
                orderWindow?.discountedIdsAndQuantity ?? null
            );
        }) as unknown as CartProduct[]) ?? []
    );
};

/**
 * [INTERNAL_FUNCTION] get cart product discount
 * @param {CartProduct} cartProduct
 * @param {OnlineFixedDiscount[]} activeFixedMenuDiscounts
 * @returns
 */
const getCartProductDiscount = (
    cartProduct: CartProduct,
    activeFixedMenuDiscounts: OnlineFixedDiscount[] | undefined | null
) => {
    return activeFixedMenuDiscounts?.find(discount => {
        const hasMatchingId = discount.id === cartProduct?.fixedDiscount?.fixedDiscountId;
        if (cartProduct?.fixedDiscount?.subscriptionProductMeta) {
            const { amountDiscount, percentageDiscount } = cartProduct.fixedDiscount.subscriptionProductMeta;
            const hasMatchingAmount = amountDiscount === discount.amount;
            const hasMatchingPercentage = percentageDiscount == discount.rate;

            return hasMatchingId && (hasMatchingAmount || hasMatchingPercentage);
        } else {
            return hasMatchingId;
        }
    });
};

/**
 * [FUNCTION] update all cart products
 * @param {OrderWindow} orderWindow
 * @param {OnlineFixedDiscount} activeFixedMenuDiscounts
 * @returns
 */
export const updateAllCartFixedDiscounts = (
    orderWindow: OrderWindow,
    activeFixedMenuDiscounts: OnlineFixedDiscount[] | null | undefined = null
) => {
    const cartTotalOriginalPrice = getDiscountedCartTotalOriginalPrice(orderWindow);

    return orderWindow?.cartProducts?.map(cartProduct => {
        if (!!cartProduct?.fixedDiscount?.fixedDiscountId && cartProduct?.fixedDiscount.isFixedDiscountActive) {
            const cartProductDiscount = getCartProductDiscount(cartProduct, activeFixedMenuDiscounts);
            if (!!cartProductDiscount) {
                return recalculatePriceAndDiscount(
                    cartProduct,
                    cartProductDiscount,
                    orderWindow.fixedDiscountedProductIds ?? [],
                    cartTotalOriginalPrice
                );
            }
            return removeDiscountAndResetPrice(cartProduct);
        }
        return removeDiscountAndResetPrice(cartProduct);
    });
};

/**
 * [TEST_SUITE]
 * [FUNCTION]
 * Pass in order window and get total fixed discount values along with total addon price
 * Calculation includes quantity only provides values if cart product is in fixed discount id array and discountProduct
 * (combined discount)
 * These values are then used in the discount rate calculation when combining discounts with an amount discount
 * @param {OrderWindow} orderWindow
 * @param {number} cartTotalOriginalPrice
 * @returns {object} { fixedDiscountValue, totalAddonPrice }
 */
export const calculateFixedDiscountTotalForAmountDiscountsAndAddonPrice = (
    orderWindow: OrderWindow,
    cartTotalOriginalPrice: number = 0
) => {
    const { fixedDiscountedProductIds, discountedProductIds, fixedDiscounts } = orderWindow;

    let totalAddonPrice = 0;
    const fixedDiscountValue = orderWindow?.cartProducts?.reduce((totalDiscount, cart) => {
        if (fixedDiscountedProductIds?.includes(cart.id) && discountedProductIds?.includes(cart.id)) {
            const cartFixedDiscount = fixedDiscounts?.find(
                discount => discount.id === cart?.fixedDiscount?.fixedDiscountId
            );
            const { orderProduct } = cart;
            const noAddonsDiscount = cart?.fixedDiscount && cart.fixedDiscount?.isFixedDiscountActive;

            let orgUnitPrice = getCartProductOrgUnitPrice(cart);
            let addonsPrice = 0;

            if (noAddonsDiscount) {
                addonsPrice = calculateTotalAddonPrices(orderProduct);
                totalAddonPrice += addonsPrice * orderProduct.quantity;
                orgUnitPrice = orgUnitPrice - addonsPrice;
            }

            const updatedOriginalPrice = orgUnitPrice * orderProduct.quantity;
            const discountRate = calculateDiscountRate(cartFixedDiscount, cartTotalOriginalPrice) ?? 0;
            const discountAmount = round(discountRate * updatedOriginalPrice, 5);
            return (totalDiscount += discountAmount);
        }
        return totalDiscount;
    }, 0);

    return {
        fixedDiscountValue,
        totalAddonPrice
    };
};

/**
 * [FUNCTION] finc fixed / subscription discount
 * @param {OnlineFixedDiscount[]} fixedDiscounts
 * @param {OnlineFixedDiscount} fixedDiscount
 * @returns {OnlineFixedDiscount}
 */
export const findFixedSubscriptionDiscount = (
    fixedDiscounts: OnlineFixedDiscount[],
    fixedDiscount?: OnlineFixedDiscount
) => {
    return fixedDiscounts?.find(discount => {
        const matchingId = discount.id === fixedDiscount?.fixedDiscountId;

        const isSubscriptionDiscount = discount.type === DISCOUNT_TYPE.SUBSCRIPTION_DISCOUNT;

        if (isSubscriptionDiscount) {
            const sameAmount = discount?.amount === fixedDiscount?.subscriptionProductMeta?.amountDiscount;
            const sameRate = discount?.rate === fixedDiscount?.subscriptionProductMeta?.percentageDiscount;

            return matchingId && (sameAmount || sameRate);
        }

        return matchingId;
    });
};

/**
 * [TEST SUITE] [FUNCTION] Full discount calculations for checkout will calculate single discount, fixed discount and combined discount
 * Or will remove discounts
 * @param {OrderWindow} orderWindow
 * @returns {OrderWindow} updarted Order window cart Products
 */
export const updateCombinedDiscountsAndOtherDiscounts = (orderWindow: OrderWindow) => {
    const cartTotalOriginalPrice = getDiscountedCartTotalAdjustedQuantities(
        orderWindow,
        orderWindow.discountedIdsAndQuantity
    );

    const { fixedDiscountedProductIds, discountedProductIds, fixedDiscounts } = orderWindow;

    let fixedDiscountTotalForAmountDiscount = 0;
    let totalAddonPrices = 0;
    if (orderWindow.discount?.amount > 0) {
        const { fixedDiscountValue, totalAddonPrice } = calculateFixedDiscountTotalForAmountDiscountsAndAddonPrice(
            orderWindow,
            cartTotalOriginalPrice
        );
        fixedDiscountTotalForAmountDiscount = fixedDiscountValue;
        totalAddonPrices = totalAddonPrice;
    }

    return orderWindow?.cartProducts?.map(cartProduct => {
        const { id } = cartProduct;
        const isFixedDiscount = fixedDiscountedProductIds?.includes(id) && !discountedProductIds?.includes(id);
        const isAppliedDiscount = !fixedDiscountedProductIds?.includes(id) && discountedProductIds?.includes(id);
        const isCombinedDiscount = fixedDiscountedProductIds?.includes(id) && discountedProductIds?.includes(id);
        const isPriceAdjustDiscount = isCartProductAPriceAdjust(cartProduct);

        if (isAppliedDiscount) {
            const cartTotalWithDeductions =
                cartTotalOriginalPrice - fixedDiscountTotalForAmountDiscount - totalAddonPrices;
            return recalculatePriceAndDiscount(
                cartProduct,
                orderWindow.discount,
                orderWindow.discountedProductIds,
                cartTotalWithDeductions,
                orderWindow.discountedIdsAndQuantity
            );
        }

        if (isCombinedDiscount) {
            const fixedDiscount =
                fixedDiscounts?.find(discount => discount.id === cartProduct?.fixedDiscount?.fixedDiscountId) ??
                ({} as FixedDiscount);

            return recalculatePriceAndCombinedDiscounts(
                cartProduct,
                orderWindow.discount,
                fixedDiscount,
                orderWindow.discountedProductIds,
                cartTotalOriginalPrice,
                fixedDiscountTotalForAmountDiscount,
                totalAddonPrices
            );
        }

        if (isFixedDiscount) {
            return recalculatePriceAndDiscount(
                cartProduct,
                findFixedSubscriptionDiscount(fixedDiscounts!, cartProduct?.fixedDiscount)!,
                orderWindow?.fixedDiscountedProductIds!,
                cartTotalOriginalPrice
            );
        }

        if (isPriceAdjustDiscount) {
            return recalculatePriceAdjustDiscount(cartProduct);
        }

        return removeDiscountAndResetPrice(cartProduct);
    });
};

/**
 * [FUNCTION] recalculates price adjust Discount
 * @param {CartProduct} cartProduct
 * @returns {CartProduct} adjusted prices + discount
 */
export const recalculatePriceAdjustDiscount = (cartProduct: CartProduct) => {
    const { orderProduct } = cartProduct;

    const combinedDiscount = !!orderProduct?.combinedDiscounts
        ? orderProduct.combinedDiscounts[0]
        : ({} as CombinedDiscount);
    const { quantityUsedForDiscount = 0 } = combinedDiscount;
    const hasQuantityDifference = quantityUsedForDiscount === orderProduct.quantity;

    if (!hasQuantityDifference) {
        /** Checking that the quantity discounted is the same - recalculating if merging same cart products at
         * same price
         */
        const updatedOrderProduct = resetDiscounts(orderProduct);
        return {
            ...cartProduct,
            orderProduct: {
                ...updatedOrderProduct,
                ...getUpdatedUnitPrice(orderProduct.unitPrice, cartProduct)
            },
            updatedUnitPrice: true
        };
    } else {
        /** No need to recalculate here as this has been already done
         * Just return cart product so it doesn't reset */
        return cartProduct;
    }
};

/**
 * [FUNCTION] removes combined discounts + value + rate
 * @param {OrderProduct} orderProduct
 * @returns {OrderProduct} order product without props
 */
export const resetDiscounts = (orderProduct: OrderProduct) => {
    const { combinedDiscounts, discountValue, discountRate, ...removeDiscount } = orderProduct;
    return removeDiscount;
};

/**
 * [FUNCTION] reset unit price of a particular cart product
 * @param {string} cartProductIdToReset
 * @param {CartProduct[]} cartProducts
 * @returns {CartProduct[]} array of cart products with reset cart product
 */
export const resetCartProductUnitPrice = (cartProductIdToReset: string, cartProducts: CartProduct[]) => {
    return cartProducts.map(cartProduct => {
        if (cartProduct.id === cartProductIdToReset) {
            const { orderProduct, menuProduct, menuBundleProduct } = cartProduct;
            const product = menuProduct || menuBundleProduct;
            const orgPrice = calculateProductPrice(
                product,
                1,
                orderProduct.modifications,
                orderProduct.selectedBundleProductItems
            );
            const updatedOrderProduct = resetDiscounts(orderProduct);
            return {
                ...cartProduct,
                orderProduct: {
                    ...updatedOrderProduct,
                    ...getUpdatedUnitPrice(orgPrice.unitPrice, cartProduct)
                },
                updatedUnitPrice: false
            };
        } else {
            return cartProduct;
        }
    });
};

/**
 * [FUNCTION] update cart products unit price by product ids passed in
 * @param {CartProduct[]} cartProducts
 * @param {number} updatedUnitPrice - updated unit price
 * @param {string[]} productIds - effected product ids
 * @returns {CartProduct[]}
 */
export const updateCartProductUnitPrice = (
    cartProducts: CartProduct[],
    updatedUnitPrice: number,
    productIds: string[]
) => {
    return cartProducts.map(cartProduct => {
        if (productIds.includes(cartProduct.id)) {
            const { orderProduct } = cartProduct;
            const updatedOrderProduct = resetDiscounts(orderProduct);
            return {
                ...cartProduct,
                orderProduct: {
                    ...updatedOrderProduct,
                    ...getUpdatedUnitPrice(updatedUnitPrice, cartProduct)
                },
                updatedUnitPrice: true
            };
        } else {
            return cartProduct;
        }
    });
};

/**
 * [INTERNAL] [FUNCTION] check if cart product is discounted
 * @param {CartProduct} cartProduct
 * @param {string[]} discountedProductIds
 * @returns {boolean} is discounted or not
 */
const isProductDiscounted = (cartProduct: CartProduct, discountedProductIds?: (string | undefined)[]) => {
    return (
        discountedProductIds &&
        (discountedProductIds.includes(cartProduct.id) || discountedProductIds.includes(cartProduct?.oldId ?? ""))
    );
};

/**
 * [FUNCTION] Deconstructs the cart product object to remove properties in order to reset the cart product
 * @param {CartProduct} cartProduct
 * @returns {CartProduct} discount removed + price reset
 */
export const removeDiscountAndResetPrice = (cartProduct: CartProduct) => {
    const { discountIds, combinedDiscounts, discountRate, discountValue, ...updatedOrderProduct } =
        cartProduct.orderProduct;

    const product = cartProduct?.menuProduct || cartProduct?.menuBundleProduct;

    const updatedPrices = calculateMenuProductOrBundlePrice(
        product!,
        updatedOrderProduct.quantity,
        updatedOrderProduct.weight,
        updatedOrderProduct.modifications ?? null,
        updatedOrderProduct.selectedBundleProductItems ?? null,
        updatedOrderProduct.addons,
        cartProduct.updatedUnitPrice ? cartProduct.orderProduct.unitPrice : null
    );

    return {
        ...cartProduct,
        orderProduct: {
            ...updatedOrderProduct,
            ...updatedPrices
        }
    };
};

/**
 * [TEST_SUITE]
 * [FUNCTION] Recalculates the cart products original unit price
 * @param {CartProduct} cartProduct - the cart product
 * @return {number} original unit price
 */
export const getCartProductOrgUnitPrice = (cartProduct: CartProduct) => {
    const { orderProduct, menuProduct, menuBundleProduct } = cartProduct;

    const menuProdOrBundle = menuProduct ? menuProduct : menuBundleProduct;

    return roundToTwo(
        getPrice(
            menuProdOrBundle,
            !!orderProduct?.modifications ? orderProduct.modifications : null,
            orderProduct.selectedBundleProductItems,
            orderProduct.addons
        )
    );
};

/** TEST SUITE */
/**
 * [TEST_SUITE] [FUNCTION] calculates addon price from order product
 * @param {OrderProduct} orderProduct
 * @returns {number}
 */
export const calculateTotalAddonPrices = (orderProduct: OrderProduct) => {
    let addonPrice = 0;
    if (orderProduct?.addons) {
        addonPrice += getTotalAddonsPrice(orderProduct?.addons);
    }
    if (orderProduct?.refBundleProductId) {
        // This calculates the addons that are added to the individual selectedBundleItems
        addonPrice += calculateBundleAddonPrice(orderProduct?.selectedBundleProductItems);
    }
    return addonPrice;
};

/**
 * [FUNCTION] Get Cart Product base price or default price
 * @param {CartProduct} cartProduct - the cart product
 * @return {number} Base Price
 */
export const getCartProductBasePrice = (cartProduct: CartProduct) => {
    const { menuProduct, menuBundleProduct } = cartProduct;
    const menuProdOrBundle = menuProduct ? menuProduct : menuBundleProduct;
    return getProductBasePrice(menuProdOrBundle);
};

/**
 * [TEST_SUITE] [FUNCTION] get discounted quantity and type for product
 * @param {CartProduct} cartProduct
 * @param {CartIdAndQuantity[] | null} cartIdAndQuantity
 * @returns {{number, DISCOUNT_TYPE}} quantity and discount type
 */
export const getQuantityForAppliedDiscount = (
    cartProduct: CartProduct,
    cartIdsAndQuantities?: CartIdAndQuantity[] | null
) => {
    const {
        orderProduct: { quantity },
        fixedDiscount
    } = cartProduct;

    const setAppliedQuantity = cartIdsAndQuantities?.find(cart => cart.cartId === cartProduct.id);

    if (setAppliedQuantity) {
        if (setAppliedQuantity.quantity >= quantity) {
            return {
                quantityUsedForDiscount: quantity,
                discountType: DISCOUNT_TYPE.APPLIED_DISCOUNT
            };
        } else {
            return {
                quantityUsedForDiscount: setAppliedQuantity.quantity,
                discountType: DISCOUNT_TYPE.QUANTITY_APPLIED_DISCOUNT
            };
        }
    } else if (fixedDiscount?.type === DISCOUNT_TYPE.SUBSCRIPTION_DISCOUNT) {
        return {
            quantityUsedForDiscount: quantity,
            discountType: DISCOUNT_TYPE.SUBSCRIPTION_DISCOUNT
        };
    } else {
        return {
            quantityUsedForDiscount: quantity,
            discountType: DISCOUNT_TYPE.APPLIED_DISCOUNT
        };
    }
};

/**
 * [FUNCTION] Created a separate Function for weighted products
 * @param {CartProduct} cartProduct - current cart product
 * @param {Discount} discount  - current applied discount
 * @returns {CartProduct} New Cart product
 */
export const calculateWeightedProductDiscount = (cartProduct: CartProduct, discount: Discount, cartTotal: number) => {
    const { orderProduct } = cartProduct;
    /** Pre Step check discount type is per product if not then use cartTotal for discount rate */
    const isPerProductDiscount = discount?.appliedDiscountType === APPLIED_DISCOUNT_TYPE.PER_PRODUCT;
    /** Step 1 calculate addons */
    const totalAddonPrice = calculateTotalAddonPrices(orderProduct);
    /** Step 2 get original unit price minus addons as this is weighted */
    const originalPricePerVarableType = getCartProductOrgUnitPrice(cartProduct) - totalAddonPrice;
    /** Step 3 set quantity - in this case weight */
    const productWeight = orderProduct.weight;
    /** Step 4 Get original Price by unit * weight + addons */
    const updatedOriginalPrice = originalPricePerVarableType * productWeight + totalAddonPrice;
    /** Step 5 calculate discount rate to apply */
    const totalToDiscount = !isPerProductDiscount ? cartTotal : updatedOriginalPrice;
    const discountRate = round(calculateDiscountRate(discount, totalToDiscount) ?? 0, 5);
    /** Step 6 calculate discount value to apply */
    const discountValue = round(updatedOriginalPrice * discountRate, 5);
    /** Step 7 Set discount type */
    const discountType = isPerProductDiscount
        ? DISCOUNT_TYPE.APPLIED_DISCOUNT_PER_PRODUCT
        : DISCOUNT_TYPE.APPLIED_DISCOUNT;
    /** Step 8 Set total price with discount taking into account value could be 0 */
    const discountedTotalPrice = updatedOriginalPrice - discountValue < 0 ? 0 : updatedOriginalPrice - discountValue;
    /** Step 9 get vat rate */
    const vatRate = getRefVatRate(cartProduct) ?? 0;
    /** Step 10 calculate new vat amount */
    const updatedVatAmount = getVatAmount(vatRate, discountedTotalPrice);
    /** Step 11 get total net price */
    const updatedNetAmount = discountedTotalPrice - updatedVatAmount;
    /** Step 12 remove any older discount that could have applied */
    const { combinedDiscounts, ...removedCombinedOrdeProduct } = orderProduct;

    /** Step 13 construct discount summary for order product */
    const discountSummary = {
        discountId: discount.id,
        name: discount.name,
        code: discount?.code ?? "",
        discountValue: discountValue,
        discountRate: discountRate,
        discountedFrom: updatedOriginalPrice,
        quantityUsedForDiscount: 1,
        discountType,
        discountOrder: 1
    };

    /** Final Step return cart Product with new calulcations */
    return {
        ...cartProduct,
        orderProduct: {
            ...removedCombinedOrdeProduct,
            id: uuidv4(),
            unitPrice: discountedTotalPrice,
            discountIds: [discount.id],
            combinedDiscounts: [discountSummary],
            discountRate: discountRate,
            discountValue,
            totalPrice: discountedTotalPrice,
            totalNetPrice: updatedNetAmount
        }
    };
};

/**
 * [TEST_SUITE] [FUNCTION] Recalculates price and discount
 * @param {CartProduct} cartProduct - the cart product
 * @param {Discount | OnlineFixedDiscount} discount - the discount object
 * @param {string[]} discountedProductIds - array of all discounted cartproduct ids
 * @param {number} cartOriginalPrice - the original price of cart
 * @param {CartIdAndQuantity[]} discountedIdsAndQuantity - quantity to discount for cart product
 * @return {CartProduct} updated cartproduct with correct price
 */
export const recalculatePriceAndDiscount = (
    cartProduct: CartProduct,
    discount: Discount | OnlineFixedDiscount,
    discountedProductIds: (string | undefined)[],
    cartOriginalPrice: number = 0,
    discountedIdsAndQuantity?: CartIdAndQuantity[] | null
) => {
    if (isProductDiscounted(cartProduct, discountedProductIds)) {
        const { orderProduct } = cartProduct;

        const isWeightedProduct = isVariablePriceType(orderProduct.priceType);

        if (isWeightedProduct) {
            return calculateWeightedProductDiscount(cartProduct, discount as Discount, cartOriginalPrice);
        }

        let quantity = orderProduct.quantity;

        const hasAppliedDiscountType = Reflect.has(discount, "appliedDiscountType");

        // Check is applied discount is for each product selected
        const isAppliedDiscountPerProduct = hasAppliedDiscountType
            ? (discount as Discount)?.appliedDiscountType === APPLIED_DISCOUNT_TYPE.PER_PRODUCT
            : false;

        // Quantity in Pos can be adjusted to only apply discount to a certain number of products
        let { quantityUsedForDiscount, discountType } = getQuantityForAppliedDiscount(
            cartProduct,
            discountedIdsAndQuantity
        );
        quantity = quantityUsedForDiscount;

        /** fixed discount no addon discount */
        const noAddonsDiscount = cartProduct?.fixedDiscount && cartProduct.fixedDiscount?.isFixedDiscountActive;

        let orgUnitPrice = getCartProductOrgUnitPrice(cartProduct);

        /** Check if product is a weighted product, if so change to multiply by the weight instead of the quantity */
        let productQuantityOrWeight = orderProduct.quantity;

        let addonsPrice = 0;
        if (noAddonsDiscount) {
            /** Calculate total of addons to remove from price before applying discount - Fixed Discount only */
            addonsPrice = calculateTotalAddonPrices(orderProduct);
            orgUnitPrice = orgUnitPrice - addonsPrice;
        }

        // Calculate original price with total quantity
        const updatedOriginalPrice = orgUnitPrice * productQuantityOrWeight;

        // Calculate price for discount - quantity can be different in applied discount
        let priceToDiscount = orgUnitPrice * (quantity ?? productQuantityOrWeight);

        // Get Price difference between full price and disounted price - Can be 0 if discounting all
        const priceDifference = updatedOriginalPrice - priceToDiscount;

        let discountId = discount ? discount.id : null;

        const discountVariant = discount?.amount ? "amount" : "rate";

        let discountValue = 0;
        let discountRate = 0;

        if (discountVariant === "rate") {
            discountRate = calculateDiscountRate(discount, cartOriginalPrice) ?? 0;
            discountValue = round(discountRate * priceToDiscount, 2);
        } else if (!isAppliedDiscountPerProduct) {
            const isOnlineFixedDiscountType = Reflect.has(discount, "type");
            /** Check is it is a subscription discount */
            const isSubscriptionDiscount = isOnlineFixedDiscountType
                ? (discount as OnlineFixedDiscount)?.type === DISCOUNT_TYPE.SUBSCRIPTION_DISCOUNT
                : false;
            /** If subscription discount then remove the price of the addons
             * If not use cart original price
             */
            let subscriptionProductOriginalPrice = isSubscriptionDiscount
                ? orgUnitPrice - calculateTotalAddonPrices(orderProduct)
                : cartOriginalPrice;

            /** Calculate the subscription discount rate */
            discountRate = calculateDiscountRate(discount, subscriptionProductOriginalPrice) ?? 0;

            if (isSubscriptionDiscount) {
                discountValue = discount?.amount ?? 0;
                if (productQuantityOrWeight > 1) {
                    /** Calculate discount value with quantity */
                    discountValue = discountValue * productQuantityOrWeight;
                    /** Calculate original price with quantity */
                    subscriptionProductOriginalPrice = subscriptionProductOriginalPrice * productQuantityOrWeight;
                }
            } else {
                discountValue = round(discountRate * priceToDiscount, 5);
            }
        }

        if (isAppliedDiscountPerProduct) {
            // cost of single items
            const costOfSingleItem = orgUnitPrice;
            // get the rate of single item - bare in mind here that the discount potentially could be more than
            // the product - so just better to use this function as anything over the item just becomes rate of 1
            const singleDiscountRate = calculateDiscountRate(discount, costOfSingleItem) ?? 0;

            // get the discount value for that single item
            const singleDiscountValue = costOfSingleItem * singleDiscountRate;

            discountValue = round(singleDiscountValue * productQuantityOrWeight, 5);

            // calculate full discount rate
            const adjustedDiscountRate = Math.min(discountValue / updatedOriginalPrice, 1);
            discountRate = round(adjustedDiscountRate, 5);
            discountType = DISCOUNT_TYPE.APPLIED_DISCOUNT_PER_PRODUCT;
        }

        let updatedTotalPrice = priceToDiscount - discountValue < 0 ? 0 : priceToDiscount - discountValue;

        const updatedUnitPrice = orgUnitPrice * (1 - discountRate) + addonsPrice;

        updatedTotalPrice += addonsPrice * productQuantityOrWeight;

        updatedTotalPrice += priceDifference;

        // Get vat amount and net price
        const vatRate = getRefVatRate(cartProduct) ?? 0;
        const updatedVatAmount = getVatAmount(vatRate, updatedTotalPrice);
        const updatedTotalNetPrice = updatedTotalPrice - updatedVatAmount;

        // part for additional adjustments, if there is a price difference in the discount values
        let updatedDiscountRate = discountRate;
        let adjustedUnitPrice = updatedUnitPrice;
        if (priceDifference && priceDifference > 0) {
            // Discount rate could be 10% but only on one item - on full cart price with 5 items this wouldn't be 10%
            updatedDiscountRate = round(discountValue / updatedOriginalPrice, 5);
            // Adjustment for unit price
            adjustedUnitPrice = round(updatedTotalPrice / orderProduct.quantity, 2);
        }

        if (isWeightedProduct) {
            adjustedUnitPrice = orgUnitPrice * productQuantityOrWeight - discountValue;
        }

        const { combinedDiscounts, ...removedCombinedOrdeProduct } = orderProduct;

        const hasCode = Reflect.has(discount, "code");

        const discountSummary = {
            discountId: discountId,
            name: discount.name,
            code: hasCode ? (discount as Discount)?.code : "",
            discountValue: discountValue,
            discountRate: discountRate,
            discountedFrom: priceToDiscount,
            quantityUsedForDiscount: quantity,
            discountType,
            discountOrder: 1
        };

        return {
            ...cartProduct,
            orderProduct: {
                ...removedCombinedOrdeProduct,
                id: uuidv4(),
                unitPrice: adjustedUnitPrice,
                discountIds: [discountId],
                combinedDiscounts: [discountSummary],
                discountRate: updatedDiscountRate,
                discountValue,
                totalPrice: updatedTotalPrice,
                totalNetPrice: updatedTotalNetPrice
            }
        } as unknown as CartProduct;
    } else {
        return removeDiscountAndResetPrice(cartProduct) as unknown as CartProduct;
    }
};

/** TEST SUITE
 * Recalculates price and a Combined Discount of fixed and applied
 * @param {CartProduct} cartProduct - the cart product
 * @param {Discount} discount - the discount object
 * @param {FixedDiscount} fixedDiscount - the fixed discount object
 * @param {string[]} combinedDiscountedProductIds - array of product ids that combined
 * @param {number} cartOriginalPrice - the original price of cart - is only pass if it is an amount discount
 * @param {number} totalFixedDiscount - total fixed discount on whole cart
 * @param {number} totalAddonPrice - total addon price for whole cart
 * @return {object} updated cartproduct with correct price
 * Note: the fixed discount is calculated first and then the applied discount is calculated after
 * Added new object combined discount - breakdown of 2 discounts
 */
export const recalculatePriceAndCombinedDiscounts = (
    cartProduct: CartProduct,
    discount: Discount,
    fixedDiscount: FixedDiscount,
    combinedDiscountedProductIds: string[],
    cartOriginalPrice: number,
    totalFixedDiscount: number = 0,
    totalAddonPrice: number = 0
) => {
    if (isProductDiscounted(cartProduct, combinedDiscountedProductIds)) {
        const { orderProduct } = cartProduct;
        /** fixed discount no addon discount */
        const noAddonsDiscount = cartProduct?.fixedDiscount && cartProduct.fixedDiscount?.isFixedDiscountActive;

        let orgUnitPrice = getCartProductOrgUnitPrice(cartProduct);

        let cartTotalPriceWithOutAddons = cartOriginalPrice;
        let addonsPrice = 0;
        if (noAddonsDiscount) {
            /** Calculate total of addons to remove from price before applying discount */
            addonsPrice = calculateTotalAddonPrices(orderProduct);
            cartTotalPriceWithOutAddons = cartTotalPriceWithOutAddons - totalAddonPrice;
            orgUnitPrice = orgUnitPrice - addonsPrice;
        }

        /** Get original price  */
        const updatedOriginalPrice = orgUnitPrice * orderProduct.quantity;

        const discountId = discount ? discount.id : null;
        const fixedDiscountId = fixedDiscount?.id ?? null;

        // working out Fixed discount rate and value
        const fixedDiscountRate = calculateDiscountRate(fixedDiscount, cartOriginalPrice) ?? 0;
        const fixedDiscountValue = round(fixedDiscountRate * updatedOriginalPrice, 2);
        const unitPriceWithFixedDiscount = orgUnitPrice - fixedDiscountValue / orderProduct.quantity;
        const updatedOrginalPriceWithDiscount = unitPriceWithFixedDiscount * orderProduct.quantity;

        let updatedTotalPrice = updatedOriginalPrice - fixedDiscountValue;

        const cartOriginalPriceWithDiscount = cartTotalPriceWithOutAddons
            ? cartTotalPriceWithOutAddons - totalFixedDiscount
            : cartTotalPriceWithOutAddons;
        let secondDiscountRate = calculateDiscountRate(discount, cartOriginalPriceWithDiscount) ?? 0;
        let secondDiscountValue =
            discount?.amount > 0
                ? round(secondDiscountRate * updatedOrginalPriceWithDiscount, 5)
                : round(secondDiscountRate * updatedOrginalPriceWithDiscount, 2);
        updatedTotalPrice = updatedTotalPrice - secondDiscountValue;
        updatedTotalPrice += addonsPrice * orderProduct.quantity;

        const fixedDiscountSummary = {
            discountId: fixedDiscountId,
            name: fixedDiscount.name,
            discountValue: fixedDiscountValue,
            discountRate: fixedDiscountRate,
            discountedFrom: updatedOriginalPrice,
            discountType: DISCOUNT_TYPE.FIXED_DISCOUNT,
            discountOrder: 1
        };

        const appliedDiscountSummary = {
            discountId: discountId,
            name: discount.name,
            code: discount?.code ?? "",
            discountValue: secondDiscountValue,
            discountRate: secondDiscountRate,
            discountedFrom: updatedOrginalPriceWithDiscount,
            discountType: DISCOUNT_TYPE.APPLIED_DISCOUNT,
            discountOrder: 2
        };

        const vatRate = getRefVatRate(cartProduct) ?? 0;
        const updatedVatAmount = getVatAmount(vatRate, updatedTotalPrice);
        const updatedTotalNetPrice = updatedTotalPrice - updatedVatAmount;

        const totalDiscountValue = (secondDiscountValue += fixedDiscountValue);
        const totalDiscountRate = totalDiscountValue / updatedOriginalPrice;
        const updatedUnitPrice = updatedTotalPrice / orderProduct.quantity;

        return {
            ...cartProduct,
            orderProduct: {
                ...orderProduct,
                unitPrice: updatedUnitPrice,
                discountIds: [fixedDiscountId, discountId],
                discountRate: totalDiscountRate,
                discountValue: totalDiscountValue,
                combinedDiscounts: [fixedDiscountSummary, appliedDiscountSummary],
                totalPrice: updatedTotalPrice,
                totalNetPrice: updatedTotalNetPrice
            }
        };
    } else {
        return removeDiscountAndResetPrice(cartProduct);
    }
};

/**
 * [FUNCTION] Convert menu product with weight to cart product
 * @param {string} cartId
 * @param {MenuProduct} menuProduct
 * @param {string} shopId
 * @param {string} menuCategoryId
 * @param {OrderWindow} activeOrderWindow
 * @param {number} cartTotalOriginalPrice
 * @param {number} weight
 * @param {ScaleInputMode} scaleType
 * @returns Cart Product for weighted products
 */
export const menuProductToCartProductWithWeight = (
    cartId: string,
    menuProduct: MenuProduct,
    shopId: string,
    menuCategoryId: string,
    activeOrderWindow: OrderWindow | null | undefined,
    cartTotalOriginalPrice: number,
    weight: number,
    scaleType: ScaleInputMode
) => {
    const selectedModifications = null;
    const selectedBundleItems = null;
    const addons = null;
    const comment = null;
    const upsell = false;
    const quantity = 1;

    const { refProduct } = menuProduct;
    const pricesAndVats = calculateMenuProductOrBundlePrice(
        menuProduct,
        quantity,
        weight,
        selectedModifications,
        selectedBundleItems,
        addons
    );

    let cartProduct = {
        id: cartId,
        menuProduct,
        orderProduct: {
            // This is to help GraphQL figure out that "similar" order products should be overwritten if these variables change
            id: uuidv4(),
            name: refProduct.name,
            refProductId: refProduct.id,
            menuCategoryId,
            refProductCategoryId: refProduct.refProductCategoryId,
            modifications: selectedModifications,
            priceType: refProduct.priceType,
            weight,
            quantity: 1,
            shopId,
            addons: addons || [],
            ...pricesAndVats,
            upsell,
            comment,
            scaleType,
            isOpenProduct: false,
            refBundleProductId: null,
            selectedBundleProductItems: null
        }
    } as unknown as CartProduct;

    if (activeOrderWindow?.discount) {
        const updatedCartOriginalPrice = cartTotalOriginalPrice + cartProduct.orderProduct.unitPrice;
        cartProduct = recalculatePriceAndDiscount(
            cartProduct,
            activeOrderWindow.discount,
            activeOrderWindow.discountedProductIds,
            updatedCartOriginalPrice,
            activeOrderWindow?.discountedIdsAndQuantity ?? null
        ) as unknown as CartProduct;
    }

    return cartProduct;
};

/**
 * [FUNCTION] convert menu product to Cart Product
 * @param {string} cartId
 * @param {MenuProduct} menuProduct
 * @param {string} shopId
 * @param {string} menuCategoryId
 * @param {Modifications | undefined | null} selectedModifications
 * @param {OrderWindow} activeOrderWindow
 * @param {number} cartTotalOriginalPrice
 * @param {number} quantity
 * @param {Addon[]} addons
 * @param {boolean} upsell
 * @param {number | null} updatedUnitPrice
 * @param {stirng | null | undefined} comment
 * @param {boolean} isStockTracked
 * @param {string | undefined | null} nickname
 * @returns Cart Product
 */
export const menuProductToCartProduct = (
    cartId: string,
    menuProduct: MenuProduct,
    shopId: string,
    menuCategoryId: string,
    selectedModifications: Modifications | Modification | null | undefined,
    activeOrderWindow: OrderWindow | null | undefined,
    cartTotalOriginalPrice: number,
    quantity: number,
    addons: Addon[],
    upsell: boolean = false,
    updatedUnitPrice: number | null = null,
    comment: string | null | undefined,
    isStockTracked: boolean = false,
    nickname: string | undefined | null,
    isLastOrderProduct: boolean
) => {
    const { refProduct } = menuProduct;
    const pricesAndVats = calculateProductPrice(
        menuProduct,
        quantity,
        selectedModifications,
        null,
        addons,
        updatedUnitPrice
    );

    let cartProduct = {
        id: cartId,
        menuProduct,
        customerMeta: {
            addedBy: nickname,
            paidBy: null,
            status: CustomerMetaStatus.ADDED
        },
        isFinished: true,
        orderProduct: {
            // This is to help GraphQL figure out that "similar" order products should be overwritten if these variables change
            id: uuidv4(),
            name: refProduct.name,
            refProductId: refProduct.id,
            menuCategoryId,
            priceType: refProduct.priceType,
            refProductCategoryId: refProduct.refProductCategoryId,
            modifications: selectedModifications,
            quantity,
            shopId,
            addons: addons || [],
            ...pricesAndVats,
            upsell,
            comment,
            isStockTracked,
            isOpenProduct: false,
            refBundleProductId: null,
            selectedBundleProductItems: null,
            weight: 0,
            isLastOrderProduct
        }
    } as unknown as CartProduct;

    if (activeOrderWindow?.discount) {
        const updatedCartOriginalPrice = cartTotalOriginalPrice + cartProduct.orderProduct.unitPrice;
        cartProduct = recalculatePriceAndDiscount(
            cartProduct,
            activeOrderWindow.discount,
            activeOrderWindow.discountedProductIds,
            updatedCartOriginalPrice,
            activeOrderWindow?.discountedIdsAndQuantity ?? null
        ) as unknown as CartProduct;
    }

    return cartProduct;
};

/**
 * [FUNCTION] temp bundle item holder
 * @param {BundleProductCategory} cat
 * @param {number} index
 * @returns
 */
export const tempBundleItemHolder = (cat: BundleProductCategory, index: number) => {
    const idx = cat.limit > 1 ? ` ${index + 1}` : "";
    return {
        name: `${cat.name}${idx}: -`,
        bundleProductCategoryId: cat.id,
        refProductId: null,
        refProductCategoryId: null,
        kdsUnitDisplayName: cat.kdsUnitDisplayName,
        modifications: {},
        addons: []
    };
};

/**
 * [FUNCTION] Creating a temp item holder
 * @param {MenuBundleProduct} menuBundleProduct
 * @returns
 */
export const createTempBundleItemHolders = (menuBundleProduct?: MenuBundleProduct) => {
    const { refBundleProduct } = menuBundleProduct || {};

    let tempBundleItems: any = [];
    refBundleProduct?.bundleProductCategories.forEach(refBundleCat => {
        let i;
        for (i = 0; i < refBundleCat.limit; i++) {
            tempBundleItems = tempBundleItems.concat([tempBundleItemHolder(refBundleCat, i)]);
        }
    });
    return tempBundleItems;
};

/**
 * [FUNCTION] temp order product from bundle product
 * @param {MenuBundleProduct | undefined} menuBundleProduct
 * @param {string} shopId
 * @param {string} menuCategoryId
 * @param {number} newQuantity
 * @param {FixedDiscount | undefined} fixedDiscount
 * @param {string | undefined} nickname
 * @returns
 */
export const tempBundleProductOrderProduct = (
    menuBundleProduct: MenuBundleProduct | undefined,
    shopId: string | undefined | null,
    menuCategoryId: string | undefined | null,
    newQuantity: number,
    fixedDiscount: FixedDiscount | undefined | null = null,
    nickname: string | undefined | null = null
) => {
    const { refBundleProduct } = menuBundleProduct || {};
    const emptyBundleItemsCategoryHolders = createTempBundleItemHolders(menuBundleProduct);
    const priceAndVats = calculateProductPrice(menuBundleProduct, newQuantity, emptyBundleItemsCategoryHolders);

    const hasStockProp = Reflect.get(menuBundleProduct ?? {}, "isStockTracked");

    return {
        id: uuidv4(),
        isFinished: false,
        menuBundleProduct,
        fixedDiscount,
        customerMeta: {
            addedBy: nickname,
            paidBy: null,
            status: CustomerMetaStatus.ADDED
        },
        orderProduct: {
            // This is to help GraphQL figure out that "similar" order products should be overwritten if these variables change
            id: uuidv4(),
            name: refBundleProduct?.name,
            refBundleProductId: refBundleProduct?.id,
            menuCategoryId,
            refProductCategoryId: refBundleProduct?.refProductCategoryId,
            selectedBundleProductItems: emptyBundleItemsCategoryHolders,
            quantity: newQuantity,
            priceType: refBundleProduct?.priceType,
            weight: 0,
            shopId,
            addons: [],
            isStockTracked: hasStockProp?.isStockTracked ?? false,
            isOpenProduct: false,
            comment: null,
            isLastOrderProduct: false,
            ...priceAndVats
        }
    };
};

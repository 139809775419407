const CryptoJS = require("crypto-js");

import { kagi } from "Constants";
import { StandardCountryVat, CountryLocaleId, swedishVatRateToAlcoholTag, AlcoholTag } from "Types";
import { allwedd } from "Utils";
import { ShownProductType, ProductByCategories } from "../../../providers/productProvider/utils";

const lowerCaseIncludes = (str: string, subString: string) => {
    return str.toLowerCase().includes(subString.toLowerCase());
};

const shouldShow = (product: any, searchString: string) => {
    return lowerCaseIncludes(product.msProduct.name + product.msProduct.description, searchString);
};

export const filterProductData = (productData: ProductByCategories, searchString: string, selectedCategory: string) => {
    if (!searchString && !selectedCategory) {
        return productData;
    }

    /**
     *  {"category1" : [product1]}
     *
     *  ["category1", [product1]]
     */

    // Expect object
    const productCategoryEntries = Object.entries(productData).map((entry: any[]) => {
        const [categoryIdx, products] = entry;

        // Expecting array
        return [
            categoryIdx,
            products.filter((product: ShownProductType) => {
                const isSelectedCategory = selectedCategory
                    ? product.msProduct.refProductCategoryId === selectedCategory
                    : true;
                return shouldShow(product, searchString) && isSelectedCategory;
            })
        ];
    });

    const returnValue: any = {};
    productCategoryEntries.forEach((entry: any) => {
        returnValue[entry[0]] = entry[1];
    });
    return returnValue;
};

export const DIRTY_PRODUCTS = "dirtyProductsStorage";

export const getSessionStorage = (key: string, initialValue = []) => {
    const item = window.sessionStorage.getItem(key);

    const bytes = CryptoJS.AES.decrypt(item, kagi + allwedd);
    const decryptedString = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedString);
};

export const setSessionStorage = (key: string, value: any[]) => {
    try {
        const encryptedObject = CryptoJS.AES.encrypt(JSON.stringify(value), kagi + allwedd).toString();

        window.sessionStorage.setItem(key, encryptedObject);
    } catch (error) {
        console.log("Error when setting value: ", error);
    }
};

export const removeSessionStorage = (key: string) => {
    try {
        window.sessionStorage.removeItem(key);
    } catch (error) {
        console.log("Error when removing value: ", error);
    }
};

/**
 * [FUNCTION] Swedish specific for alcohol vat rates
 * 
 * * Checks product alcohol tag with vat rate and makes sure product vat rate is correct
 * 
 * @param products 
 * @returns 
 */
export const getSwedishAlcoholProductIdsWithVatIssue = (products: ShownProductType[]) => {
    return products
        .filter(value => {
            const alcoholTag = value.msProduct?.productTags?.alcoholTag;
            const productId = value.msProduct?.id;
            if (alcoholTag && productId) {
                const vatRate = value.msProduct?.vatRate ?? StandardCountryVat[CountryLocaleId.sv_SE];
                /** Check vat to alcohol tag */
                const rateToTag = swedishVatRateToAlcoholTag[vatRate];
                const correctVat = rateToTag?.includes(alcoholTag as AlcoholTag) ?? false;
                return !correctVat;
            }
            return false;
        })
        .map(product => product.msProduct.id!);
};
